const timer = document.querySelectorAll(".countdown__timer");
const endTextElements = document.querySelectorAll(".countdown__end-text");

/**
 * Gets the current time in the Netherlands.
 * @returns {Object} An object containing the hours, minutes, seconds, and UTC time.
 */
function getCurrentTimeInNetherlands() {
  const nowUtc = new Date();
  const nlLocale = 'nl-NL';
  const options = { 
    timeZone: 'Europe/Amsterdam',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  };

  const formatter = new Intl.DateTimeFormat(nlLocale, options);
  const formattedTime = formatter.format(nowUtc);
  const [hours, minutes, seconds] = formattedTime.split(':').map(Number);

  return { hours, minutes, seconds };
}

/**
 * Calculates the remaining time until the specified end time.
 * @param {number} endTimeDate - The end time in milliseconds.
 * @returns {Object} An object containing the hours, minutes, seconds, and distance.
 */
function getRemainingTime(endTimeDate) {
  const { hours, minutes, seconds } = getCurrentTimeInNetherlands();

  const nowInNL = new Date();
  nowInNL.setHours(hours, minutes, seconds, 0);
  const nowInMs = nowInNL.getTime();

  const distance = endTimeDate - nowInMs;

  const remainingHours = Math.floor(distance / (1000 * 60 * 60));
  const remainingMinutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  const remainingSeconds = Math.floor((distance % (1000 * 60)) / 1000);

  return { remainingHours, remainingMinutes, remainingSeconds, distance };
}

/**
 * Formats the time components into a string.
 * @param {number} hours - The number of hours.
 * @param {number} minutes - The number of minutes.
 * @param {number} seconds - The number of seconds.
 * @param {string} hoursLabel - The text to append to the hours.
 * @param {string} minutesLabel - The text to append to the minutes.
 * @param {string} secondsLabel - The text to append to the seconds.
 * @returns {string} The formatted time string.
 */
function formatTime(hours, minutes, seconds, hoursLabel, minutesLabel, secondsLabel) {
  const formattedHours = hours < 10 ? `0${hours}` : hours;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
  return `${formattedHours}${hoursLabel} ${formattedMinutes}${minutesLabel} ${formattedSeconds}${secondsLabel}`;
}

/**
 * Updates the countdown display with the formatted time.
 * @param {HTMLElement} display - The element where the countdown is displayed.
 * @param {string} formattedTime - The formatted time string to display.
 */
function updateCountdownDisplay(display, formattedTime) {
  display.textContent = formattedTime;
}

/**
 * Checks if the countdown has ended and updates the display accordingly.
 * @param {number} distance - The remaining time in milliseconds.
 * @param {HTMLElement} display - The element where the countdown is displayed.
 * @param {HTMLElement} endTextElement - The element to display when the countdown ends.
 * @returns {boolean} True if the countdown has ended, otherwise false.
 */
function checkCountdownEnd(distance, display, endTextElement) {
  if (distance < 0) {
    display.style.display = "none";
    endTextElement.style.display = "block";
    return true;
  }
  display.style.display = "block";
  endTextElement.style.display = "none";
  return false;
}

/**
 * Updates the countdown timer.
 * @param {number} endTimeDate - The end time in milliseconds
 * @param {HTMLElement} display - The element where the countdown is displayed
 * @param {HTMLElement} endTextElement - The element to show when the countdown ends
 * @param {string} hoursLabel - The label for hours
 * @param {string} minutesLabel - The label for minutes
 * @param {string} secondsLabel - The label for seconds
 * @returns {boolean} True if the countdown has ended, otherwise false.
 */
function updateCountdown(endTimeDate, display, endTextElement, hoursLabel, minutesLabel, secondsLabel) {
  const { remainingHours, remainingMinutes, remainingSeconds, distance } = getRemainingTime(endTimeDate);

  if (checkCountdownEnd(distance, display, endTextElement)) {
    return true;
  }

  const formattedTime = formatTime(remainingHours, remainingMinutes, remainingSeconds, 
    hoursLabel, minutesLabel, secondsLabel);
  updateCountdownDisplay(display, formattedTime);
  return false;
}

/**
 * Starts a dynamic countdown timer.
 * @param {string} endTime - The end time as a string.
 * @param {HTMLElement} display - The element where the countdown is displayed.
 * @param {HTMLElement} endTextElement - The element to show when the countdown ends.
 */
export default function startDynamicCountdown(endTime, display, endTextElement) {
  const endTimeDate = new Date(endTime).getTime();

  if (!endTime || !display || !endTextElement) return;

  const hoursLabel = display.getAttribute("data-hour-text");
  const minutesLabel = display.getAttribute("data-minute-text");
  const secondsLabel = display.getAttribute("data-second-text");

  const interval = setInterval(() => {
    const countdownEnded = updateCountdown(endTimeDate, display, endTextElement,
                                          hoursLabel, minutesLabel, secondsLabel);
    if (countdownEnded) clearInterval(interval);
  }, 1000);

  updateCountdown(endTimeDate, display, endTextElement, hoursLabel, minutesLabel, secondsLabel);
}
