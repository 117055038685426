import startDynamicCountdown from "../promo-countdown/promo-countdown";

/**
 * This file is used to initialize the promo bar component.
 */
export default function initPromoBar() {
  const promoBar = document.querySelector('.promo-bar');
  const closePromoBar = document.querySelector('.promo-bar__content-close--close-icon');
  const header = document.querySelector('.responsive-page header');
  const promoBarWrapper = $('.promo-bar-wrapper');
  const promoBarContents = document.querySelectorAll('.promo-bar__content');
  const counterCurrent = document.querySelector(".promo-bar__counter--current");

  if (!promoBar || !header) return;

  if (sessionStorage.getItem('promoBarDismissed') === 'true' || 
      window.pageName === 'catalog' || 
      window.pageName === 'cart') {
    return;
  }

  promoBar.style.display = 'flex';
  header.classList.remove('header--promo-bar-closed')

  if (closePromoBar) {
    closePromoBar.addEventListener('click', () => {
      promoBar.style.display = 'none';
      sessionStorage.setItem('promoBarDismissed', 'true');
      header.classList.add('header--promo-bar-closed');
    });
  }

  if (promoBarContents.length <= 1) {
    return;
  }

      promoBarWrapper.slick({
        slidesToShow: 1,
        infinite: true,
        slidesToScroll: 1,
        draggable: false,
        dots: false,
        arrows: true,
        prevArrow: $('.promo-bar__nav-button--left'),
        nextArrow: $('.promo-bar__nav-button--right'),
        appendArrows: $('.promo-bar-container'),
        responsive: [
          {
            breakpoint: 768,
            settings: {
              dots: true,
              arrows: false,
              swipe: true,
              adaptiveHeight: true,
            },
          },
        ],
      });

      if (window.themeName === "helloprint") {
      function updateCounter() {
        const currentIndex = promoBarWrapper.slick("slickCurrentSlide") + 1;
        counterCurrent.innerHTML = currentIndex;
      }

      promoBarWrapper.on("afterChange", function () {
        updateCounter();
      });

      updateCounter();
  }
}

function initializeCountdowns() {
  const countdownTimers = document.querySelectorAll('.countdown__timer');

  countdownTimers.forEach((countdownElement, index) => {
    const countdownEnd = countdownElement.getAttribute("data-countdown-end");
    const endTextElement = document.querySelectorAll('.countdown__end-text')[index];

    if (countdownEnd) {
      startDynamicCountdown(countdownEnd, countdownElement, endTextElement);
    }
  });
}

document.addEventListener('DOMContentLoaded', () => {
  initializeCountdowns();
});

window.addEventListener('resize', () => {
  let resizeTimeout;
  clearTimeout(resizeTimeout);
  resizeTimeout = setTimeout(() => {
    initializeCountdowns();
  }, 200);
});
