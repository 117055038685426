function positionTooltip(tooltipTrigger, tooltip) {
    const distance = 10;
    const triggerRect = tooltipTrigger.getBoundingClientRect();

    tooltip.style.position = 'absolute';
    tooltip.style.top = `${window.scrollY + triggerRect.top - tooltip.offsetHeight - distance}px`;
    tooltip.style.left = `${window.scrollX + triggerRect.left + (triggerRect.width - tooltip.offsetWidth) / 2}px`;
    tooltip.style.zIndex = '9999';
}

/**
 * Function to copy the promo code value to the clipboard and display a tooltip
 * @param {HTMLElement} promoCodeContainer - The container element of the promo code
 * @param {HTMLElement} tooltip - The tooltip element
 */
async function copyPromoCodeValue(promoCodeContainer, tooltip) {
    const promoCodeText = promoCodeContainer.querySelector('.promo-code__value')?.textContent;
    const successMessage = promoCodeContainer.querySelector('.promo-code__tooltip')?.textContent.trim();
    const errorMessage = promoCodeContainer.querySelector('.promo-code__tooltip--error')?.textContent.trim();
    const successSvg = promoCodeContainer.querySelector('.promo-code__tooltip--success')?.outerHTML;

    try {
        await navigator.clipboard.writeText(promoCodeText);
        tooltip.innerHTML = `${successSvg} <span>${successMessage}</span>`;
        tooltip.style.display = 'block';
    } catch (err) {
        tooltip.textContent = errorMessage;
    }
}

/**
 * Initialize the copy promo code functionality
 */
export default function initializeCopyPromoCode() {
    const promoBar = document.querySelector('.promo-bar');

    if (!promoBar) return;

    promoBar.addEventListener('click', async (event) => {
        const promoCodeContainer = event.target.closest('.promo-code');
        if (!promoCodeContainer) return;

        let tooltip;

        if (!tooltip) {
            tooltip = document.createElement('div');
            tooltip.classList.add('promo-code__dynamic-tooltip');
            document.body.appendChild(tooltip);
        }

        positionTooltip(promoCodeContainer, tooltip);

        await copyPromoCodeValue(promoCodeContainer, tooltip);

        setTimeout(() => tooltip.remove(), 2000);
    });
}
